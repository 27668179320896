/* eslint-disable @typescript-eslint/camelcase */
export const onfidoLocales = {
  acquisition: {
    accessibility: {
      camera_view: 'Camera',
      close_sdk_screen: 'Scherm sluiten',
      country_select: 'Selecteer land',
      cross_device_verification: 'Hoe kunt u doorgaan op mobiel?',
      dismiss_alert: 'Dismiss alert',
      document_types: 'Geldige identiteitsbewijzen',
      replay_video: 'Replay your recorded video',
      selfie_video_actions: 'Actions to record a selfie video',
      shutter: 'Neem een foto',
      start_recording: 'Start recording',
      stop_recording: 'Stop recording',
    },
    back: 'terug',
    bank_building_society_statement: 'Bank or building society statement',
    benefit_letters: 'Benefits Letter',
    cancel: 'Cancel',
    capture: {
      bank_building_society_statement: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 3 months</strong>',
          title: 'Submit statement',
          webcam: 'Position your bank statement in the frame (it will be automatically detected)',
        },
      },
      benefit_letters: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 12 months</strong>',
          title: 'Submit letter',
          webcam: 'Position your benefits letter in the frame (it will be automatically detected)',
        },
      },
      council_tax: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 12 months</strong>',
          title: 'Submit letter',
          webcam: 'Position your council tax letter in the frame (it will be automatically detected)',
        },
      },
      driving_licence: {
        back: {
          instructions: 'Upload back of license from your computer',
          title: 'Submit license (back)',
          webcam: 'Position the back of license in the frame (it will be automatically detected)',
        },
        front: {
          instructions: 'Upload front of license from your computer',
          title: 'Submit license (front)',
          webcam: 'Position the front of license in the frame (it will be automatically detected)',
        },
      },
      face: {
        instructions: 'Upload a selfie from your computer',
        intro: {
          accessibility: {
            selfie_capture_tips: 'Tips to take a good selfie',
          },
          glasses_instruction: 'Remove your glasses, if necessary',
          selfie_instruction: 'Face forward and make sure your eyes are clearly visible',
          subtitle: "We'll compare it with your document",
          title: 'Take a selfie',
        },
        title: 'Take a selfie',
        upload_title: 'Selfie',
      },
      government_letter: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 12 months</strong>',
          title: 'Government Letter',
          webcam: 'Position your government letter in the frame (it will be automatically detected)',
        },
      },
      liveness: {
        challenges: {
          done_next: "When you're done, press next",
          done_stop: "When you're done, press stop",
          left: 'left',
          movement: 'Look over your %{side} shoulder',
          next: 'Next',
          position_face: 'Position your face in the oval',
          recite: 'Say each digit out loud',
          right: 'right',
        },
        intro: {
          continue: 'Continue',
          speak_out_loud: 'One will involve <strong>speaking out loud</strong>',
          title: "Let's make sure nobody's impersonating you",
          two_actions: "We'll ask you to film yourself performing <strong>2 simple actions</strong>",
        },
        press_record: 'Press record and follow the instructions',
        recording: 'Recording',
        start: 'Start',
        stop: 'Stop',
      },
      national_identity_card: {
        back: {
          instructions: 'Upload the achterzijde van uw kaart vanaf uw computer. Alleen JPG, PNG en PDF zijn toegestaan.',
          title: 'Verstuur uw identiteitskaart (achterzijde)',
          webcam: 'Plaats de achterkant van uw kaart in het frame (hij wordt automatisch herkend)',
        },
        front: {
          instructions: 'Upload the voorkant van uw kaart vanaf uw computer. Alleen JPG, PNG en PDF zijn toegestaan.',
          title: 'Verstuur uw identiteitskaart (voorzijde)',
          webcam: 'Plaats de voorkant van uw kaart in het frame (hij wordt automatisch herkend)',
        },
      },
      passport: {
        front: {
          instructions:
            'Upload een foto van de voorkant van een geldig paspoort (de harde plastic kaart). Alleen JPG, PNG en PDF zijn toegestaan.',
          title: 'Verstuur de foto van uw paspoort',
          webcam: 'Plaats de achterkant van uw kaart in het frame (hij wordt automatisch herkend)',
        },
      },
      switch_device: 'Verder gaan op mobiel',
      take_photo: 'Neem een foto',
      upload_document: 'Uploaden',
      upload_file: 'of upload een foto - geen scan of kopie gebruiken',
      utility_bill: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 3 months</strong>',
          title: 'Submit bill',
          webcam: 'Position your utility bill in the frame (it will be automatically detected)',
        },
      },
    },
    close: 'sluiten',
    complete: {
      message: 'Identificatie afgerond',
      submessage: 'Dank u wel',
    },
    confirm: {
      bank_building_society_statement: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      benefit_letters: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      confirm: 'Bevestig',
      continue: 'Doorgaan',
      council_tax: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      document: {
        alt: 'Foto van uw document',
        title: 'Controleer de kwaliteit van de afbeelding',
      },
      driving_licence: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      enlarge_image: {
        close: 'Sluiten',
        enlarge: 'Vergroot afbeelding',
      },
      face: {
        standard: {
          alt: 'Photo of your face',
          message: 'Make sure your selfie clearly shows your face',
          title: 'Check selfie',
        },
        video: {
          title: 'Check selfie video',
        },
      },
      national_identity_card: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      passport: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      redo: 'Opnieuw',
      utility_bill: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
    },
    continue: 'Doorgaan',
    council_tax: 'Council Tax Letter',
    cross_device: {
      client_success: {
        body: 'Het updaten van de aanvraag op uw computer kan enkele seconden duren',
        sub_title: 'Ga terug naar uw computer om door te gaan',
        title: 'Upload geslaagd',
      },
      intro: {
        face: {
          title: 'Identificatie veilig vervolgen op uw mobiel',
          take_photos: 'Open de link om de identificatie af te ronden',
          action: 'Doorgaan',
        },
        document: {
          title: 'Identificatie veilig vervolgen op uw mobiel',
          take_photos: 'Open de link om de identificatie af te ronden',
          action: 'Doorgaan',
        },
        link: {
          sub_title: 'Wij sturen u eenmalig een link naar uw mobiele telefoon',
          link_copy: {
            action: 'Kopieer',
            success: 'Gekopieerd',
          },
        },
        action: 'Stuur mij de beveiligde link',
        description_li_1: 'Stuur een beveiligde link naar mijn telefoon',
        description_li_2: 'Open de link om de identificatie af te ronden',
        description_li_3: 'Keer terug om de aanvraag af te ronden',
        sms: 'Stuur een beveiligde link naar mijn telefoon',
        'take-photos': 'Open de link om de identificatie af te ronden',
        'return-to-computer': 'Keer terug om de aanvraag af te ronden',
        return_computer: 'Keer terug om de aanvraag af te ronden',
        sub_title: 'Zo werkt het:',
        title: 'Doorgaan op mobiel',
      },
      link: {
        sub_title: 'Wij sturen u eenmalig een link naar uw mobiele telefoon',
        button_copy: {
          action: 'Verstuur link',
          status: 'Versturen',
        },
        link_copy: {
          action: 'Kopieer',
          success: 'Gekopieerd',
        },
        copy_link_label: 'Kopieer de link in uw mobiele browser',
        copy_link_option: 'Link kopiëren',
        copy_link_sub_title: 'Open the link on your mobile',
        copy_link: {
          action: 'Kopieer',
          success: 'Gekopieerd',
        },
        options_divider_label: 'of',
        qr_code_option: 'Scan de QR code',
        qr_code_sub_title: 'Scan de QR code met uw mobiel',
        qr_code: {
          help_label: 'Hoe scant u de QR code?',
          help_step_1: 'Richt de camera van uw telefoon op de QR code',
          help_step_2: 'Werkt dit niet? Download dan de QR code scanner in de Google Play of App Store',
        },
        sms_label: 'Voer het nummer van uw mobiele telefoon in:',
        sms_option: 'Ontvang een link per SMS',
        sms_sub_title: 'Stuur de one-time link naar uw mobiel',
        title: 'Ontvang een beveiligde link',
      },
      loading: 'Laden….',
      mobile_connected: {
        tips: {
          item_1: 'Houd het huidige scherm open terwijl u verder gaat op uw mobiele telefoon',
          item_2: 'De link die wij u hebben verstuurd blijft één uur actief',
          item_3: 'Ververs deze pagina niet',
        },
        title: {
          message: 'Verbonden met uw mobiele telefoon',
          submessage: 'Zodra u klaar bent, nemen wij u mee naar de volgende stap',
        },
      },
      mobile_notification_sent: {
        bold_message: 'Het kan enkele minuten duren voordat u de SMS van ons ontvangt',
        resend_link: 'Link nogmaals versturen',
        submessage: 'Wij hebben een beveiligde link verstuurd naar: %{number}',
        tips: {
          item_1: 'Houd het huidige scherm open terwijl u verder gaat op uw mobiele telefoon',
          item_2: 'De link die wij u hebben verstuurd blijft één uur actief',
        },
        title: 'Controleer uw mobiel',
      },
      phone_number_placeholder: 'Voer uw telefoonnummer in',
      submit: {
        action: 'Identificatie afronden',
        multiple_docs_uploaded: 'Documenten geüpload',
        one_doc_uploaded: 'Document geüpload',
        selfie_uploaded: 'Selfie uploaded',
        sub_title: 'Wij kunnen nu uw identiteit verifiëren',
        title: 'Bedankt, wij hebben alles in goede orde ontvangen',
        video_uploaded: 'Video uploaded',
      },
      switch_device: {
        header: 'Maak een foto met uw telefoon',
        submessage: 'Identificatie veilig vervolgen op uw mobiel',
      },
      tips: 'Tips',
    },
    document_selector: {
      identity: {
        driving_licence_hint: 'Voor- en achterkant',
        hint: 'Kies het document waarmee u zichzelf wil identificeren',
        national_identity_card_hint: 'Voor- en achterkant',
        passport_hint: 'Pagina met uw pasfoto',
        title: 'Identificeren',
      },
      proof_of_address: {
        benefits_letter_hint: 'Government authorised household benefits eg. Jobseeker allowance, Housing benefit, Tax credits',
        estatements_accepted: 'e-statements accepted',
        government_letter_hint: 'Any government issued letter eg. Benefits entitlement, Voting letters, Tax letters, etc',
        hint: 'These are the documents most likely to show your current home address',
        title: 'Select a %{country} document',
        utility_bill_hint: 'Gas, electricity, water, landline, or broadband',
        utility_bill_warning: 'Sorry, no mobile phone bills',
      },
    },
    driving_licence: 'Rijbewijs',
    errors: {
      camera_inactive_no_fallback: {
        instruction: 'Make sure your device has a working camera',
      },
      camera_inactive: {
        instruction: 'Check that it is connected and functional. You can also <fallback>continue verification on your phone</fallback>',
        message: 'Camera werkt niet?',
      },
      camera_not_working_no_fallback: {
        instruction: 'Zorg ervoor dat de camera van uw apparaat werkt',
      },
      camera_not_working: {
        instruction: 'Mogelijk is de verbinding verbroken. <fallback>Probeer in plaats daarvan je telefoon te gebruiken</fallback>.',
        message: 'Camera werkt niet',
      },
      forbidden_client_error: {
        instruction: 'Deze link werkt uitsluitend op mobiele apparaten',
        message: 'Er gaat iets mis',
      },
      generic_client_error: {
        instruction: 'Wij vragen u de identificatie opnieuw te starten op uw computer',
        message: 'Er gaat iets mis',
      },
      glare_detected: {
        instruction: 'Alle gegevens moeten duidelijk en leesbaar zijn',
        message: 'Te veel reflectie',
      },
      interrupted_flow_error: {
        instruction: 'Probeer het opnieuw op een ander apparaat',
        message: 'Camera niet gevonden',
      },
      invalid_capture: {
        instruction: 'Zorg ervoor dat het gehele document op de foto staat',
        message: 'Geen document gevonden',
      },
      invalid_number: {
        message: 'Controleer uw nummer',
      },
      invalid_size: {
        instruction: 'Maximaal 10MB.',
        message: 'Het bestand is te groot.',
      },
      invalid_type: {
        instruction: 'Probeer een ander bestandsformaat.',
        message: 'Bestand niet geüpload.',
      },
      lazy_loading: {
        message: 'Er is iets mis gegaan bij het laden van dit onderdeel',
      },
      liveness_timeout: {
        instruction: "Remember to press stop when you're done. <fallback>Redo video actions</fallback>",
        message: 'U heeft te lang niets gedaan',
      },
      multiple_faces: {
        instruction: 'Only your face can be in the selfie',
        message: 'Multiple faces found',
      },
      no_face: {
        instruction: 'Your face is needed in the selfie',
        message: 'No face found',
      },
      server_error: {
        instruction: 'Probeer het opnieuw',
        message: 'Verbinding verbroken',
      },
      sms_failed: {
        instruction: 'Kopieer de link naar uw mobiele telefoon',
        message: 'Er gaat iets mis',
      },
      sms_overuse: {
        instruction: 'Kopieer de link naar uw mobiele telefoon',
        message: 'Te veel pogingen ondernomen',
      },
      unsupported_android_browser: {
        instruction: 'Probeer het opnieuw in de laatste versie van Google Chrome.',
        message: 'Deze browser wordt niet ondersteund',
      },
      unsupported_file: {
        instruction: 'Probeer een JPG of PNG bestand',
        message: 'Dit bestandsformaat wordt niet ondersteund',
      },
      unsupported_ios_browser: {
        instruction: 'Probeer het opnieuw in de laatste versie van Safari',
        message: 'Deze browser wordt niet ondersteund',
      },
    },
    government_letter: 'Government Letter',
    loading: 'Laden….',
    mobilePhrases: {
      capture: {
        driving_licence: {
          back: {
            instructions: 'Take a photo of the back of your license',
          },
          front: {
            instructions: 'Take a photo of the front of your license',
          },
        },
        face: {
          instructions: 'Take a selfie showing your face',
        },
        national_identity_card: {
          back: {
            instructions: 'Maak een foto van de achterkant van uw kaart',
          },
          front: {
            instructions: 'Maak een foto van de voorkant van uw kaart',
          },
        },
        passport: {
          front: {
            instructions: 'Maak een foto van de harde plastic pagina in uw paspoort met daarop uw pasfoto',
          },
        },
      },
      errors: {
        camera_inactive: {
          instruction: 'Take a photo using the <fallback>basic camera mode</fallback> instead',
        },
        camera_not_working: {
          instruction: 'Take a photo using the <fallback>basic camera mode</fallback> instead',
        },
      },
    },
    national_identity_card: 'Identiteitskaart',
    passport: 'Paspoort',
    proof_of_address: {
      guidance: {
        continue: 'Doorgaan',
        current_address: 'Current address',
        full_name: 'Full name',
        issue_date: 'Issue date or summary period',
        logo: 'Logo',
        make_sure_it_shows: 'Make sure it clearly shows:',
      },
      intro: {
        is_recent: 'Is your most <strong>recent</strong> document',
        matches_signup: '<strong>Matches</strong> the address you used on signup',
        requirements: "You'll need a document that:",
        shows_address: 'Shows your <strong>current</strong> address',
        start: 'Start verification',
        title: "Let's verify your %{country} address",
      },
    },
    short_driving_licence: 'Rijbewijs',
    short_national_identity_card: 'kaart',
    short_passport: 'paspoort',
    utility_bill: 'Utility Bill',
    webcam_permissions: {
      access_denied: 'Camera access is denied',
      allow: 'Allow',
      allow_access: 'Allow camera access',
      click_allow: 'We cannot verify you without using your camera',
      enable_webcam: 'Enable camera',
      enable_webcam_for_selfie: 'When prompted, you must enable camera access to continue',
      follow_steps: 'Follow these steps to recover camera access:',
      grant_access: 'Grant access to your camera from your browser settings',
      if_denied: "If you deny camera access, you won't be able to take pictures and complete verification process.",
      recover_access: 'Recover camera access to continue face verification',
      recovery: 'Recovery',
      refresh: 'Refresh',
      refresh_page: 'Refresh this page to restart the identity verification process',
      why: 'Why do I need to do this?',
    },
    welcome: {
      description_p_1: 'To open a bank account, we will need to verify your identity.',
      description_p_2: 'It will only take a couple of minutes.',
      next_button: 'Identificeren',
      title: 'Open your new bank account',
    },
  },
  remediation: {
    accessibility: {
      camera_view: 'Camera',
      close_sdk_screen: 'Scherm sluiten',
      country_select: 'Selecteer land',
      cross_device_verification: 'Hoe kunt u doorgaan op mobiel?',
      dismiss_alert: 'Dismiss alert',
      document_types: 'Geldige identiteitsbewijzen',
      replay_video: 'Replay your recorded video',
      selfie_video_actions: 'Actions to record a selfie video',
      shutter: 'Maak een foto',
      start_recording: 'Start recording',
      stop_recording: 'Stop recording',
    },
    back: 'terug',
    bank_building_society_statement: 'Bank or building society statement',
    benefit_letters: 'Benefits Letter',
    cancel: 'Cancel',
    capture: {
      bank_building_society_statement: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 3 months</strong>',
          title: 'Submit statement',
          webcam: 'Position your bank statement in the frame (it will be automatically detected)',
        },
      },
      benefit_letters: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 12 months</strong>',
          title: 'Submit letter',
          webcam: 'Position your benefits letter in the frame (it will be automatically detected)',
        },
      },
      council_tax: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 12 months</strong>',
          title: 'Submit letter',
          webcam: 'Position your council tax letter in the frame (it will be automatically detected)',
        },
      },
      driving_licence: {
        back: {
          instructions: 'Upload back of license from your computer',
          title: 'Submit license (back)',
          webcam: 'Position the back of license in the frame (it will be automatically detected)',
        },
        front: {
          instructions: 'Upload front of license from your computer',
          title: 'Submit license (front)',
          webcam: 'Position the front of license in the frame (it will be automatically detected)',
        },
      },
      face: {
        instructions: 'Upload a selfie from your computer',
        intro: {
          accessibility: {
            selfie_capture_tips: 'Tips to take a good selfie',
          },
          glasses_instruction: 'Remove your glasses, if necessary',
          selfie_instruction: 'Face forward and make sure your eyes are clearly visible',
          subtitle: "We'll compare it with your document",
          title: 'Take a selfie',
        },
        title: 'Take a selfie',
        upload_title: 'Selfie',
      },
      government_letter: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 12 months</strong>',
          title: 'Government Letter',
          webcam: 'Position your government letter in the frame (it will be automatically detected)',
        },
      },
      liveness: {
        challenges: {
          done_next: "When you're done, press next",
          done_stop: "When you're done, press stop",
          left: 'left',
          movement: 'Look over your %{side} shoulder',
          next: 'Next',
          position_face: 'Position your face in the oval',
          recite: 'Say each digit out loud',
          right: 'right',
        },
        intro: {
          continue: 'Continue',
          speak_out_loud: 'One will involve <strong>speaking out loud</strong>',
          title: "Let's make sure nobody's impersonating you",
          two_actions: "We'll ask you to film yourself performing <strong>2 simple actions</strong>",
        },
        press_record: 'Press record and follow the instructions',
        recording: 'Recording',
        start: 'Start',
        stop: 'Stop',
      },
      national_identity_card: {
        back: {
          instructions: 'Upload een foto van de achterkant van uw identiteitskaart. (JPG, PNG of PDF)',
          title: 'Verstuur uw identiteitskaart (achterzijde)',
          webcam: 'Plaats de achterkant van uw kaart in het frame (hij wordt automatisch herkend)',
        },
        front: {
          instructions: 'Upload een foto van de voorkant van uw identiteitskaart. (JPG, PNG of PDF)',
          title: 'Verstuur uw identiteitskaart (voorzijde)',
          webcam: 'Plaats de voorkant van uw kaart in het frame (hij wordt automatisch herkend)',
        },
      },
      passport: {
        front: {
          instructions: 'Upload een foto van de voorkant van uw paspoort (harde plastic kaart). (JPG, PNG of PDF)',
          title: 'Verstuur de foto van uw paspoort',
          webcam: 'Plaats de achterkant van uw kaart in het frame (hij wordt automatisch herkend)',
        },
      },
      switch_device: 'Verder gaan op mobiel',
      take_photo: 'Maak een foto',
      upload_document: 'Uploaden',
      upload_file: 'Upload een foto',
      utility_bill: {
        front: {
          instructions: 'Provide the whole document page for best results',
          sub_title: 'Must be issued in the <strong>last 3 months</strong>',
          title: 'Submit bill',
          webcam: 'Position your utility bill in the frame (it will be automatically detected)',
        },
      },
    },
    close: 'sluiten',
    complete: {
      message: 'Identificatie afgerond',
      submessage: 'Dank u wel',
    },
    confirm: {
      bank_building_society_statement: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      benefit_letters: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      confirm: 'Bevestig',
      continue: 'Doorgaan',
      council_tax: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      document: {
        alt: 'Foto van uw document',
        title: 'Controleer de kwaliteit van de afbeelding',
      },
      driving_licence: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      enlarge_image: {
        close: 'Sluiten',
        enlarge: 'Vergroot afbeelding',
      },
      face: {
        standard: {
          alt: 'Photo of your face',
          message: 'Make sure your selfie clearly shows your face',
          title: 'Check selfie',
        },
        video: {
          title: 'Check selfie video',
        },
      },
      national_identity_card: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      passport: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
      redo: 'Opnieuw',
      utility_bill: {
        message: 'Is de afbeelding scherp en leesbaar?',
      },
    },
    continue: 'Doorgaan',
    council_tax: 'Council Tax Letter',
    cross_device: {
      client_success: {
        body: 'Het updaten van het identificatieproces op uw computer kan enkele seconden duren.',
        sub_title: 'Ga terug naar uw computer om door te gaan',
        title: 'Upload ID-document geslaagd',
      },
      intro: {
        face: {
          title: 'Identificatie veilig vervolgen op uw mobiel',
          take_photos: 'Open de link om de identificatie af te ronden',
          action: 'Doorgaan',
        },
        document: {
          title: 'Identificatie veilig vervolgen op uw mobiel',
          take_photos: 'Open de link om de identificatie af te ronden',
          action: 'Doorgaan',
        },
        link: {
          sub_title: 'Wij sturen u eenmalig een link uw mobiele telefoon.',
          link_copy: {
            action: 'Kopieer',
            success: 'Gekopieerd',
          },
        },
        action: 'Stuur mij de beveiligde link',
        description_li_1: 'Stuur per sms een beveiligde link naar mijn telefoon.',
        description_li_2: 'Open de link om de identificatie af te ronden',
        description_li_3: 'Keer terug om de aanvraag af te ronden',
        sms: 'Stuur per sms een beveiligde link naar mijn telefoon.',
        'take-photos': 'Open de link om de identificatie af te ronden',
        'return-to-computer': 'Keer terug om de aanvraag af te ronden',
        return_computer: 'Keer terug om de aanvraag af te ronden',
        sub_title: 'Zo werkt het:',
        title: 'Doorgaan op mobiel',
      },
      link: {
        sub_title: 'Wij sturen u eenmalig een link uw mobiele telefoon.',
        button_copy: {
          action: 'Verstuur link',
          status: 'Versturen',
        },
        link_copy: {
          action: 'Kopieer',
          success: 'Gekopieerd',
        },
        copy_link_label: 'Kopieer de link in uw mobiele browser',
        copy_link_option: 'Link kopiëren',
        copy_link_sub_title: 'Open the link on your mobile',
        copy_link: {
          action: 'Kopieer',
          success: 'Gekopieerd',
        },
        options_divider_label: 'of',
        qr_code_option: 'Scan de QR code',
        qr_code_sub_title: 'Scan de QR code met uw mobiel',
        qr_code: {
          help_label: 'Hoe scant u de QR code?',
          help_step_1: 'Richt de camera van uw telefoon op de QR code',
          help_step_2: 'Werkt dit niet? Download dan de QR code scanner in de Google Play of App Store',
        },
        sms_label: 'Voer het nummer van uw mobiele telefoon in:',
        sms_option: 'Ontvang een link per SMS',
        sms_sub_title: 'Stuur de one-time link naar uw mobiel',
        title: 'Ontvang een beveiligde link',
      },
      loading: 'Laden….',
      mobile_connected: {
        tips: {
          item_1: 'Houd het huidige scherm open terwijl u verder gaat op uw mobiele telefoon',
          item_2: 'De link die wij naar u hebben gestuurd blijft één uur actief.',
          item_3: 'Ververs deze pagina niet',
        },
        title: {
          message: 'Verbonden met uw mobiele telefoon',
          submessage: 'Zodra u klaar bent, nemen wij u mee naar de volgende stap',
        },
      },
      mobile_notification_sent: {
        bold_message: 'Het kan enkele minuten duren voordat u de SMS van ons ontvangt',
        resend_link: 'Link nogmaals versturen',
        submessage: 'Wij hebben een beveiligde link verstuurd naar: %{number}',
        tips: {
          item_1: 'Houd het huidige scherm open terwijl u verder gaat op uw mobiele telefoon',
          item_2: 'De link die wij naar u hebben gestuurd blijft één uur actief.',
        },
        title: 'Controleer uw mobiel',
      },
      phone_number_placeholder: 'Voer uw telefoonnummer in',
      submit: {
        action: 'Identificatie afronden',
        multiple_docs_uploaded: 'Documenten geüpload',
        one_doc_uploaded: 'Document geüpload',
        selfie_uploaded: 'Selfie uploaded',
        sub_title: 'Wij kunnen nu uw identiteit verifiëren',
        title: 'Bedankt, wij hebben alles in goede orde ontvangen',
        video_uploaded: 'Video uploaded',
      },
      switch_device: {
        header: 'Maak een foto met uw telefoon',
        submessage: 'Identificatie veilig vervolgen op uw mobiel',
      },
      tips: 'Tips',
    },
    document_selector: {
      identity: {
        driving_licence_hint: 'Voor- en achterkant',
        hint: 'Kies het document waarmee u zichzelf wilt identificeren.',
        national_identity_card_hint: 'Voor- en achterkant',
        passport_hint: 'Pagina met uw pasfoto',
        title: 'Upload uw ID-document',
      },
      proof_of_address: {
        benefits_letter_hint: 'Government authorised household benefits eg. Jobseeker allowance, Housing benefit, Tax credits',
        estatements_accepted: 'e-statements accepted',
        government_letter_hint: 'Any government issued letter eg. Benefits entitlement, Voting letters, Tax letters, etc',
        hint: 'These are the documents most likely to show your current home address',
        title: 'Select a %{country} document',
        utility_bill_hint: 'Gas, electricity, water, landline, or broadband',
        utility_bill_warning: 'Sorry, no mobile phone bills',
      },
    },
    driving_licence: 'Rijbewijs',
    errors: {
      camera_inactive_no_fallback: {
        instruction: 'Make sure your device has a working camera',
      },
      camera_inactive: {
        instruction: 'Check that it is connected and functional. You can also <fallback>continue verification on your phone</fallback>',
        message: 'Camera werkt niet?',
      },
      camera_not_working_no_fallback: {
        instruction: 'Zorg ervoor dat de camera van uw apparaat werkt',
      },
      camera_not_working: {
        instruction: 'Mogelijk is de verbinding verbroken. <fallback>Probeer in plaats daarvan je telefoon te gebruiken</fallback>.',
        message: 'Camera werkt niet',
      },
      forbidden_client_error: {
        instruction: 'Deze link werkt uitsluitend op mobiele apparaten',
        message: 'Er gaat iets mis',
      },
      generic_client_error: {
        instruction: 'Wij vragen u de identificatie opnieuw te starten op uw computer',
        message: 'Er gaat iets mis',
      },
      glare_detected: {
        instruction: 'Alle gegevens moeten duidelijk en leesbaar zijn',
        message: 'Te veel reflectie',
      },
      interrupted_flow_error: {
        instruction: 'Probeer het opnieuw op een ander apparaat',
        message: 'Camera niet gevonden',
      },
      invalid_capture: {
        instruction: 'Zorg ervoor dat het gehele document op de foto staat',
        message: 'Geen document gevonden',
      },
      invalid_number: {
        message: 'Controleer uw nummer',
      },
      invalid_size: {
        instruction: 'Maximaal 10MB.',
        message: 'Het bestand is te groot.',
      },
      invalid_type: {
        instruction: 'Probeer een ander bestandsformaat.',
        message: 'Bestand niet geüpload.',
      },
      lazy_loading: {
        message: 'Er is iets mis gegaan bij het laden van dit onderdeel',
      },
      liveness_timeout: {
        instruction: "Remember to press stop when you're done. <fallback>Redo video actions</fallback>",
        message: 'U heeft te lang niets gedaan',
      },
      multiple_faces: {
        instruction: 'Only your face can be in the selfie',
        message: 'Multiple faces found',
      },
      no_face: {
        instruction: 'Your face is needed in the selfie',
        message: 'No face found',
      },
      server_error: {
        instruction: 'Probeer het opnieuw',
        message: 'Verbinding verbroken',
      },
      sms_failed: {
        instruction: 'Kopieer de link naar uw mobiele telefoon',
        message: 'Er gaat iets mis',
      },
      sms_overuse: {
        instruction: 'Kopieer de link naar uw mobiele telefoon',
        message: 'Te veel pogingen ondernomen',
      },
      unsupported_android_browser: {
        instruction: 'Probeer het opnieuw in de laatste versie van Google Chrome.',
        message: 'Deze browser wordt niet ondersteund',
      },
      unsupported_file: {
        instruction: 'Probeer een JPG of PNG bestand',
        message: 'Dit bestandsformaat wordt niet ondersteund',
      },
      unsupported_ios_browser: {
        instruction: 'Probeer het opnieuw in de laatste versie van Safari',
        message: 'Deze browser wordt niet ondersteund',
      },
    },
    government_letter: 'Government Letter',
    loading: 'Laden….',
    mobilePhrases: {
      capture: {
        driving_licence: {
          back: {
            instructions: 'Take a photo of the back of your license',
          },
          front: {
            instructions: 'Take a photo of the front of your license',
          },
        },
        face: {
          instructions: 'Take a selfie showing your face',
        },
        national_identity_card: {
          back: {
            instructions: 'Maak een foto van de achterkant van uw kaart',
          },
          front: {
            instructions: 'Maak een foto van de voorkant van uw kaart',
          },
        },
        passport: {
          front: {
            instructions: 'Maak een foto van de harde plastic pagina in uw paspoort met daarop uw pasfoto',
          },
        },
      },
      errors: {
        camera_inactive: {
          instruction: 'Take a photo using the <fallback>basic camera mode</fallback> instead',
        },
        camera_not_working: {
          instruction: 'Take a photo using the <fallback>basic camera mode</fallback> instead',
        },
      },
    },
    national_identity_card: 'Identiteitskaart',
    passport: 'Paspoort',
    proof_of_address: {
      guidance: {
        continue: 'Doorgaan',
        current_address: 'Current address',
        full_name: 'Full name',
        issue_date: 'Issue date or summary period',
        logo: 'Logo',
        make_sure_it_shows: 'Make sure it clearly shows:',
      },
      intro: {
        is_recent: 'Is your most <strong>recent</strong> document',
        matches_signup: '<strong>Matches</strong> the address you used on signup',
        requirements: "You'll need a document that:",
        shows_address: 'Shows your <strong>current</strong> address',
        start: 'Start verification',
        title: "Let's verify your %{country} address",
      },
    },
    short_driving_licence: 'Rijbewijs',
    short_national_identity_card: 'kaart',
    short_passport: 'paspoort',
    utility_bill: 'Utility Bill',
    webcam_permissions: {
      access_denied: 'Camera access is denied',
      allow: 'Allow',
      allow_access: 'Allow camera access',
      click_allow: 'We cannot verify you without using your camera',
      enable_webcam: 'Enable camera',
      enable_webcam_for_selfie: 'When prompted, you must enable camera access to continue',
      follow_steps: 'Follow these steps to recover camera access:',
      grant_access: 'Grant access to your camera from your browser settings',
      if_denied: "If you deny camera access, you won't be able to take pictures and complete verification process.",
      recover_access: 'Recover camera access to continue face verification',
      recovery: 'Recovery',
      refresh: 'Refresh',
      refresh_page: 'Refresh this page to restart the identity verification process',
      why: 'Why do I need to do this?',
    },
    welcome: {
      description_p_1: 'To open a bank account, we will need to verify your identity.',
      description_p_2: 'It will only take a couple of minutes.',
      next_button: 'Identificeren',
      title: 'Open your new bank account',
    },
  },
};
