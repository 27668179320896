import React, { Fragment, useContext } from 'react';

import { UploadIdStart } from './start';
import { CountrySelect } from './countrySelect';
import { Onfido } from './onfido';
import { CompleteIdFlow } from './complete';

import { StoreContext } from '../../App';

export const UploadId: React.FC = () => {
  const store = useContext(StoreContext);
  const { currentScreen } = store.AppState;

  return (
    <Fragment>
      {currentScreen.subpage === 1 && <UploadIdStart />}
      {currentScreen.subpage === 2 && <CountrySelect />}
      {currentScreen.subpage === 3 && <Onfido />}
      {currentScreen.subpage === 4 && <CompleteIdFlow />}
    </Fragment>
  );
};
