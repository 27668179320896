import React, { useState, useEffect, useRef } from 'react';

import { default as searchIcon } from '../../assets/search_icon.svg';
import { CountryInterface } from '../../constants/countries';

import './styles.scss';

interface SuggestInputInterface {
  values: CountryInterface[];
  onChange: Function;
  defaultSuggest?: CountryInterface;
  suggestLabel?: string;
  placeholder?: string;
}

const visibleSuggestions = 4;
const suggestionsHeight = 52;

export const SuggestInput: React.FC<SuggestInputInterface> = ({
  values,
  onChange,
  defaultSuggest,
  placeholder,
  suggestLabel,
}: SuggestInputInterface) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const node = useRef<any>(null);

  const [currentLabel, setCurrentLabel] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [autoselectVisible, setAutoselectVisible] = useState(true);
  const [defaultSuggestVisible, setDefaultSuggestVisible] = useState(false);

  const handleInput = (value: string): void => {
    setCurrentLabel(value);
    setDefaultSuggestVisible(false);
    setAutoselectVisible(true);
    onChange && onChange('');
  };

  const selectValue = (value: CountryInterface): void => {
    setAutoselectVisible(false);
    setDefaultSuggestVisible(false);
    setCurrentLabel(value.name);
    onChange && onChange(value.id);
  };

  const handleOutsideClick = (event: { target: object }): void => {
    if (node.current.contains(event.target)) {
      return;
    }
    setAutoselectVisible(false);
    setDefaultSuggestVisible(false);
  };

  const handleClear = (): void => {
    setCurrentLabel('');
    onChange && onChange('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return (): void => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const filtered = values.filter((value) => value.name.toLowerCase().includes(currentLabel.toLowerCase()));
    setSuggestions(filtered);
  }, [currentLabel]);

  return (
    <div ref={node} className={`suggest-input__wrapper ${hasFocus && (autoselectVisible || defaultSuggestVisible) && 'focused'}`}>
      <img src={searchIcon} className="search-icon" />
      {currentLabel.length ? <span className="clear-input" onClick={(): void => handleClear()} /> : ''}
      <input
        type="text"
        value={currentLabel}
        onChange={(e): void => handleInput(e.target.value)}
        onFocus={(): void => {
          setHasFocus(true);
          defaultSuggest && !currentLabel.length && setDefaultSuggestVisible(true);
        }}
        onBlur={(): void => setHasFocus(false)}
        placeholder={placeholder}
        className="suggest-input__input"
      />
      {autoselectVisible && currentLabel.length >= 1 && suggestions.length ? (
        <ul
          className="suggest-input__search"
          style={{
            height: `${(suggestions.length < visibleSuggestions ? suggestions.length : visibleSuggestions) * suggestionsHeight + 10}px`,
            bottom: `-${(suggestions.length < visibleSuggestions ? suggestions.length : visibleSuggestions) * suggestionsHeight + 7}px`,
          }}
        >
          {suggestions.map((value, index) => {
            if (index <= visibleSuggestions) {
              return (
                <li key={value.id} onClick={(): void => selectValue(value)}>
                  <span className={`fp ${value.id.toLowerCase()}`} />
                  {value.name}
                </li>
              );
            }
          })}
        </ul>
      ) : (
        ''
      )}
      {autoselectVisible && currentLabel.length >= 1 && !suggestions.length && (
        <ul className="suggest-input__search suggest-input__search--no-results">
          <li>No Results Found</li>
        </ul>
      )}
      {defaultSuggestVisible && defaultSuggest && (
        <>
          {suggestLabel ? <span className="suggest-input__suggest-label">{suggestLabel}</span> : ''}
          <ul className="suggest-input__autosuggest" style={{ height: '62px', bottom: '-59px' }}>
            <li key={defaultSuggest.id} onClick={(): void => selectValue(defaultSuggest)}>
              <span className={`fp ${defaultSuggest.id.toLowerCase()}`} />
              {defaultSuggest.name}
            </li>
          </ul>
        </>
      )}
    </div>
  );
};
